<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12" lg="10">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            Subcategorías
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              small
              @click="dialog = true"
            >
              Agregar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-card-text class="py-0">
                  <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    id="id"
                    filled
                    dense
                    v-model="search"
                  ></v-text-field>
                </v-card-text>
              </v-col>
            </v-row>
            <v-data-table
            	:loading="cargar"
              :headers="headers"
              :items="subcategorias"
              sort-by="estatus"
              class="elevation-0"
              :search="search"
              dense
            >                
              <template v-slot:item.editar="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  color="blue"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>

              <template v-slot:item.estatus="{ item }">
                <v-chip v-if="item.estatus == 1" color="green" dark small>Activo</v-chip>
                <v-chip v-else color="red" dark small>Inactivo</v-chip>
              </template>

              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-autocomplete
              v-model="categoria"
              :items="categorias"
              label="Categoría"
              return-object
              item-text="categoria"
              item-value="idcategorias"
              :rules="[v => !!v || 'Campo requerido']"
              outlined
              dense
            ></v-autocomplete>


            <v-text-field
              v-model="editedItem.subcategoria"
              label="Subcategoria"
              outlined
              dense
              :rules="[v => !!v || 'Campo requerido']"
            ></v-text-field>


            <v-checkbox 
              label="Estatus" 
              v-model="editedItem.estatus" 
            >
            </v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	cargar:false,
      valid: true,
      search:'',
      text:'',
      color:'',
      snackbar: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Id',            align: 'left',sortable: false, value: 'idsubcategorias'},
        { text: 'Familia',       align: 'left',sortable: false, value: 'familia'},
        { text: 'Categoria',     align: 'left',sortable: false, value: 'categoria'},
        { text: 'Subcategoria',  align: 'left',sortable: false, value: 'subcategoria'},
        { text: 'Estatus',       align: 'left',sortable: false, value: 'estatus'},
        { text: 'Editar',        align: 'left',sortable: false, value: 'editar'},
      ],

      subcategorias: [],
      editedIndex: -1,
      editedItem: {
        idsubcategorias:0,
        subcategoria: '',
        idcatego: '',
        categoria: '',
        estatus: 0,
      },
      defaultItem: {
        idsubcategorias:0,
        subcategoria: '',
        idcatego: '',
        categoria: '',
        estatus: 0,
      },

      categorias:[],
      categoria:null,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva categoría' : 'Editar Categoría'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

      initialize () {
        this.subcategorias = []
        this.cargar = true
        this.$http.get('subcategorias.all').then(response =>{
          this.subcategorias = response.data
          this.subcategorias.forEach(element=>{
            element.estatus == 1 ? true:false
          })
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        this.$http.get('categorias.activo').then(response =>{
          this.categorias = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.subcategorias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.categoria = {idcategorias: this.editedItem.idcatego, categoria: this.editedItem.categoria}
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.subcategorias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.subcategorias.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if(this.$refs.form.validate()){
          this.editedItem.estatus == false ? 0 : 1
          this.editedItem.idcatego = this.categoria.idcategorias

          if (this.editedIndex > -1) {
            this.$http.put('subcategorias.update/'+ this.editedItem.idsubcategorias, this.editedItem).then(response=>{
              this.validarSuccess('Subcategoría actualizada')
              this.initialize()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          } else {
            this.$http.post('subcategorias.add', this.editedItem).then(response=>{
              this.validarSuccess('Subcategoría agregada')
              this.initialize()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }
          this.close()
        }
    },
  },
  }
</script>